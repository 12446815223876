<template>
  <v-dialog v-model="alert.show" persistent max-width="1000" content-class="elevation-0">
    <v-alert v-if="!alert.action" v-model="alert.show" border="left" dismissible prominent :type="alert.type">
      {{ alert.type == 'success' ? $t(alert.message) : alert.message }}
    </v-alert>
    <v-alert v-if="!!alert.action" prominent :type="alert.type">
      <v-row align="center">
        <v-col class="grow">{{ alert.message }}</v-col>
        <v-col class="shrink d-flex column">
          <v-btn color="info darken-3 mx-2" @click="doSomething"><v-icon>mdi-check</v-icon></v-btn>
          <v-btn color="info darken-3 mx-2" @click="alert.show = !alert.show"><v-icon>mdi-close</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-dialog>
</template>

<script>
import { call } from "vuex-pathify";
export default {
  name: "UserAlert",
  props: {
    alert: {
      type: Object,
      default: () => ({ show: false, message: "", type: "error" }),
      validator: (v) =>
        ["success", "info", "warning", "error"].includes(v.type),
    },
  },
  updated: function () {
    if (!!this.alert.show && this.alert.type == 'success') {
      setTimeout(() => this.dismissAlert(), 1000);
    }
  },
  methods: {
    dismissAlert: call("app/dismissAlert"),
    doSomething () {
      this.alert.action(this.alert.params)
      if(!!this.alert.callback) this.alert.callback()
    }
  },
};
</script>