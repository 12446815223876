<template>
  <v-main style="height: 100vh;">
    <v-overlay style="z-index: 999999!important;" :value="!!isLoading">
      <div class="logoloader-container">
        <v-img :src="require('@/assets/MyFamily-nikita-logo.svg')"></v-img>
        <div class="loader-container">
          <div class="white-lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
      </div>
    </v-overlay>
    <v-container fluid>
      <user-alert :alert="alert"></user-alert>
      <router-view :key="$route.path" />
    </v-container>
  </v-main>
</template>

<script>
import { sync, get } from "vuex-pathify";
import UserAlert from "./widgets/UserAlert";

export default {
  name: "DefaultView",
  components: {
    UserAlert
  },
  computed: {
    alert: sync("app/alert"),
    isLoading: sync("utils/isLoading"),
    pullDown: sync("utils/pullDown"),
  },
};
</script>
<style>

  .v-label { color: black!important; }
  .v-event-summary {
    color: black;
    font-weight: bold;
  }

  .mini-img {
    width: 20px;
    height: auto;
    object-fit: contain;
  }

  .bg-white {
    background: white;
  }

  .v-icon {
    font-size: 25px!important;
  }

  .pointer {
    cursor: pointer;
  }

  .pointer:hover {
    color: #cc8800;
  }

  .googleautocompleteinput {
    border-bottom: 1px solid #b4b4b4;
    width: 100%;
    padding-bottom: 3px;
    font-size: medium;
    color: #b4b4b4;
    margin-top: 4px;
    padding: 12px 0px;
    outline: none;
  }

  .googleautocompleteinput:focus-visible, .googleautocompleteinput:focus {
    border-top: none!important;
    border-bottom: 3px solid #3E5565!important;
    width: 100%;
    font-size: medium;
    color: #333333;
    margin-top: 4px;
    padding: 12px 0px;
  }

  .googleautocompleteinput:hover {
    border-bottom: 1px solid #3E5565;
    width: 100%;
    padding-bottom: 3px;
    font-size: medium;
    color: #333333;
    margin-top: 4px;
    padding: 12px 0px;
  }

  .modal-main-content {
    height: 70vh;
    overflow-y: scroll;
  }

  .modal-main-content-65 {
    height: 65vh;
    overflow-y: scroll;
  }

  .modal-main-content-45 {
    height: 45vh;
    max-height: 45vh;
    overflow-y: scroll;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px; 
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #3E5565; 
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3E5565; 
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }


/* loader test */
.logoloader-container {
  z-index: 999;
  display:flex; 
  flex-direction: column
}

.loader-container {
  z-index: 999;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
}

.white-lds-ellipsis {
  position: relative;
  width: 80px;
  height: 80px;
}
.white-lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.white-lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.white-lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.white-lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.white-lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.pulse { animation: pulse 2s infinite; }
.pulse-round { border-radius:50%; animation: pulse 2s infinite; }

@-webkit-keyframes pulse {
  0% { -webkit-box-shadow: 0 0 0 0 rgba(255,22,22, 0.4); }
  75% { -webkit-box-shadow: 0 0 0 10px rgba(244,22,22, 0); }
  100% { -webkit-box-shadow: 0 0 0 0 rgba(244,22,22, 0); }
}

@keyframes pulse {
  0% { -moz-box-shadow: 0 0 0 0 rgba(244,22,22, 0.4); box-shadow: 0 0 0 0 rgba(244,22,22, 0.4); }
  75% { -moz-box-shadow: 0 0 0 10px rgba(244,22,22, 0); box-shadow: 0 0 0 10px rgba(244,22,22, 0); }
  100% { -moz-box-shadow: 0 0 0 0 rgba(244,22,22, 0); box-shadow: 0 0 0 0 rgba(244,22,22, 0); }
}

</style>
